// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.7.0
//   protoc               v6.30.2
// source: cbinsights/userintelservice/userintelservice.proto

/* eslint-disable */

export const protobufPackage = "userintelservice";

/** version 4.1.0 */

export enum FeatureType {
  FEATURE_TYPE_UNSPECIFIED = "FEATURE_TYPE_UNSPECIFIED",
  FEATURE_TYPE_JOB_PERSONAL_BRIEFING = "FEATURE_TYPE_JOB_PERSONAL_BRIEFING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum IntelSource {
  SOURCE_UNSPECIFIED = "SOURCE_UNSPECIFIED",
  SOURCE_USER_INPUT = "SOURCE_USER_INPUT",
  SOURCE_INTERNAL_INPUT = "SOURCE_INTERNAL_INPUT",
  SOURCE_CBI_GONG = "SOURCE_CBI_GONG",
  SOURCE_SALESFORCE = "SOURCE_SALESFORCE",
  SOURCE_USER_ENGAGEMENT_ACTIVITY = "SOURCE_USER_ENGAGEMENT_ACTIVITY",
  SOURCE_LINKEDIN = "SOURCE_LINKEDIN",
  SOURCE_ZOOMINFO = "SOURCE_ZOOMINFO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum CbiEntityType {
  UNKNOWN_CBI_ENTITY_TYPE = "UNKNOWN_CBI_ENTITY_TYPE",
  COMPANY_ENTITY_TYPE = "COMPANY_ENTITY_TYPE",
  INVESTOR_ENTITY_TYPE = "INVESTOR_ENTITY_TYPE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface UpsertFeatureFeedbackRequest {
  idEntity: number;
  idFeatureType: FeatureType;
  score: number;
}

export interface FeatureFeedback {
  idEntity: number;
  idFeatureType: FeatureType;
  score: number;
}

export interface UpsertFeatureFeedbackResponse {
  feedback: FeatureFeedback | undefined;
}

export interface BatchGetFeatureFeedbackRequest {
  idEntities: number[];
  idFeatureType: FeatureType;
}

export interface BatchGetFeatureFeedbackResponse {
  feedbacks: FeatureFeedback[];
}

export interface JobFunction {
  idJobFunction: number;
  /**
   * name refers to the canonical name for the job function
   * on updates, only supply the name if it is created client side and idJobFunction is for "Other"
   */
  name: string;
  description: string;
}

export interface UserJobFunction {
  jobFunction: JobFunction | undefined;
  userValidated: boolean;
  optIn: boolean;
  source: IntelSource;
}

export interface JobFocusAreas {
  focusAreas: string;
  userValidated: boolean;
  optIn: boolean;
  source: IntelSource;
}

export interface JobResponsibility {
  responsibility: string;
  userValidated: boolean;
  optIn: boolean;
  source: IntelSource;
}

export interface JobTitle {
  title: string;
  userValidated: boolean;
  optIn: boolean;
  source: IntelSource;
}

export interface ProfileJobFocus {
  jobFunction: UserJobFunction | undefined;
  focusAreas: JobFocusAreas | undefined;
  responsibility: JobResponsibility | undefined;
  title: JobTitle | undefined;
}

export interface GetProfileJobFocusRequest {
  idUser: number;
  field_mask: string[] | undefined;
}

export interface GetProfileJobFocusResponse {
  jobFocus: ProfileJobFocus | undefined;
  allJobFunctions: JobFunction[];
}

export interface UpdateProfileJobFocusRequest {
  idUser: number;
  jobFocus: ProfileJobFocus | undefined;
  update_mask: string[] | undefined;
}

/** ProfileTargetRegion will display the free text input from data sources, including human input, gong etc. */
export interface ProfileTargetRegion {
  displayText: string;
  userValidated: boolean;
  optIn: boolean;
  source: IntelSource;
}

export interface ProfileInterestTopic {
  topic: string;
  userValidated: boolean;
  optIn: boolean;
  source: IntelSource;
}

export interface Interests {
  topics: ProfileInterestTopic[];
  targetRegions: ProfileTargetRegion[];
}

export interface GetProfileInterestsRequest {
  idUser: number;
}

export interface GetProfileInterestsResponse {
  interests: Interests | undefined;
}

export interface UpdateProfileInterestsRequest {
  idUser: number;
  interests:
    | Interests
    | undefined;
  /**
   * update_mask simply identifies which top level fields in 'interests' you are updating i.e. "topics" or "targetRegions"
   * Since this endpoint replaces, all data from the request is used and populated in new DB rows
   */
  update_mask: string[] | undefined;
}

export interface Empty {
}

export interface UserIntel {
  idUser: number;
  dossier: string;
  topics: string[];
  tsUpdate: number;
}

export interface ListUserIntelRequest {
  idUser: number[];
  limit: number;
  offset: number;
  tsAfter: string;
}

export interface ListUserIntelResponse {
  userIntel: UserIntel[];
}

export interface Company {
  idCbiEntity: number;
  name: string;
  source: IntelSource;
}

export interface Topic {
  topic: string;
  source: IntelSource;
}

export interface UserPreferences {
  companies: Company[];
  topics: Topic[];
}

export interface UpdateUserPreferencesRequest {
  userPreferences: UserPreferences | undefined;
}

export interface GetEntityDataFromDomainRequest {
  domain: string;
  skipSupplementalData: boolean;
}

export interface GetEntityDataFromDomainResponse {
  idCbiEntity: number;
  idCompany: number;
  idInvestor: number;
  name: string;
  sector: string;
  industry: string;
  subindustry: string;
  idStatus: number;
  idPrimaryCbiEntityType: CbiEntityType;
  expertCollectionNames: string[];
  marketReportNames: string[];
  competitors: Company[];
  investedOrgs: Company[];
  briefingTopics: string[];
  briefingOrgs: Company[];
  description: string;
  domain: string;
}

export interface BatchGetEntityDataFromDomainRequest {
  domains: string[];
  skipSupplementalData: boolean;
}

export interface BatchGetEntityDataFromDomainResponse {
  entityDataFromDomains: GetEntityDataFromDomainResponse[];
}

export interface GetEntityForIdUsersRequest {
  idUsers: number[];
}

export interface UserEntityInfo {
  idUser: number;
  idCbiEntity: number;
}

export interface GetEntityForIdUsersResponse {
  userEntityInfo: UserEntityInfo[];
}

export interface GetBriefingTopicsOrgsRequest {
  idUser: number;
  idCbiEntity: number;
  limit: number;
}

export interface GetBriefingTopicsOrgsResponse {
  idUser: number;
  briefingTopics: string[];
  briefingOrgs: Company[];
}
