import { LiveSearchResponse } from '@cbinsights/integratedsearchservice/integratedsearchservice';
import { UseQueryResult, useQuery } from 'react-query';
import { GetLiveSearch } from '.';
import { getLiveSearch } from '../api/GetLiveSearch';

type Params = Parameters<typeof getLiveSearch>[0];
type Return = LiveSearchResponse;

export const useGetLiveSearch = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetLiveSearch, Params]>(
    ['GetLiveSearch', params],
    () => getLiveSearch(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
