import { UseQueryResult, useQuery } from 'react-query';
import { GetProfileInterestsResponse } from '@cbinsights/userintelservice/userintelservice';
import { GetProfileInterests } from '.';
import { getProfileInterests } from '../api/GetProfileInterests';

type Params = Parameters<typeof getProfileInterests>[0];
type Return = GetProfileInterestsResponse;

export const useGetProfileInterests = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetProfileInterests, Params]>(
    ['GetProfileInterests', params],
    () => getProfileInterests(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
