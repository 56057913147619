import { useMemo, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Tag, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import EditableText from 'client/component-library/EditableText/EditableText';
import { useGetPackagesQuery } from 'client/modules/trial-package/services/hooks/useGetPackagesQuery';
import { UserRoutes } from 'client/modules/users/RouteHelpers';
import { useGetTeamMembersQuery } from '../services/hooks/useGetTeamMembersQuery';
import ActionsDropDown from '../components/ActionsDropDown';
import { downloadValidation } from '../components/Downloads/Downloads';
import { FEATURE_IDS } from '../constants/features';
import useUpdateUserCredit from './useUpdateUserCredit';

export interface TeamMemberData {
  userId: number;
  name: string;
  email: string;
  package: string;
  startDate: string;
  endDate: string;
  downloadLimit: number;
  downloadsUsed: number;
  admin: boolean;
  owner: boolean;
  expired: boolean;
}

export interface FormerTeamMemberData {
  userId: number;
  name: string;
  email: string;
  package: string;
  startDate: string;
  endDate: string;
}

const leaveOnlyMatchedUsers = (filter: string) => (teamMember) =>
  filter === '' ||
  teamMember.firstName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
  teamMember.lastName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
  teamMember.email.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
  String(teamMember.idUser).indexOf(filter) >= 0;

export const useTeamMembers = (idTeam: number) => {
  const { data: teamMembers, isLoading: isLoadingTeamMembers, refetch: refetchGetTeamMembers } =
    useGetTeamMembersQuery({
      enabled: Boolean(idTeam),
      params: { idTeam, getOldTeamMembers: true },
    });
  const { data: packagesData } = useGetPackagesQuery({
    enabled: true,
    params: {
      include_deleted: true,
    },
  });

  const { onSetUserQuotas, onUpdateFeatureCredits } = useUpdateUserCredit({
    idTeam,
    featureId: FEATURE_IDS.downloads,
  });

  const onUpdateDownloadsUsed = (userId: number, newValue: string) => {
    const downloadsUsed = parseInt(newValue, 10);

    if (Number.isNaN(downloadsUsed)) {
      message.error('Please enter a valid number');
      return;
    }

    onUpdateFeatureCredits(userId, downloadsUsed)
      .then(() => {
        message.success('Downloads Used updated successfully!');
        refetchGetTeamMembers();
      })
      .catch(() => {
        message.error('Unable to update Downloads Used. Please try again');
      });
  };

  const onUpdateDownloadLimit = (userId: number, newValue: string) => {
    const downloadLimit = parseInt(newValue, 10);

    if (Number.isNaN(downloadLimit)) {
      message.error('Please enter a valid number');
      return;
    }

    onSetUserQuotas(userId, downloadLimit)
      .then(() => {
        message.success('Download Limit updated successfully!');
        refetchGetTeamMembers();
      })
      .catch(() => {
        message.error('Unable to update Download Limit. Please try again');
      });
  };

  const columns: ColumnsType<TeamMemberData> = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      render: (userId) => (
        <Link className="underline" to={`${UserRoutes.PageIndex}/${userId}`}>
          {userId}
        </Link>
      ),
    },
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      render: (text, { email }) => (
        <>
          <strong>{text}</strong>
          <div>{email}</div>
        </>
      ),
    },
    {
      title: '',
      render: (_, { admin, owner }) => (
        <>
          {owner && <Tag color="#2db7f5">Owner</Tag>}
          {admin && <Tag color="#87d068">Admin</Tag>}
        </>
      ),
    },
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'package',
      render: (text, { expired }) => ({
        props: {
          style: {
            fontWeight: expired ? 'bold' : 'normal',
          },
        },
        children: text,
      }),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Download Limit',
      dataIndex: 'downloadLimit',
      key: 'downloadLimit',
      render: (downloadLimit, { userId }) => (
        <EditableText
          text={downloadLimit}
          validation={downloadValidation}
          onSave={(newValue) => onUpdateDownloadLimit(userId, newValue)}
        />
      ),
    },
    {
      title: 'Downloads Used',
      dataIndex: 'downloadsUsed',
      key: 'downloadsUsed',
      render: (downloadsUsed, { userId }) => (
        <EditableText
          text={downloadsUsed}
          validation={downloadValidation}
          onSave={(newValue) => onUpdateDownloadsUsed(userId, newValue)}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      align: 'center',
      render: (text, record) => {
        return (
          <ActionsDropDown
            userId={record.userId}
            isAdmin={record.admin}
            refetchTeamMembers={refetchGetTeamMembers}
            email={record.email}
            teamId={idTeam}
          />
        );
      },
    },
  ];

  const formerColumns: ColumnsType<FormerTeamMemberData> = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      render: (userId) => (
        <Link className="underline" to={`${UserRoutes.PageIndex}/${userId}`}>
          {userId}
        </Link>
      ),
    },
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      render: (text, { email }) => (
        <>
          <strong>{text}</strong>
          <div>{email}</div>
        </>
      ),
    },
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
  ];

  const [teamMemberFilter, setTeamMemberFilter] = useState('');

  const data: TeamMemberData[] = useMemo(() => {
    if (!teamMembers) {
      return [];
    }

    return teamMembers.currentTeamMembers
      .filter(leaveOnlyMatchedUsers(teamMemberFilter))
      .map((member) => {
        const packageName = packagesData?.packages.find(
          (packageInfo) => packageInfo.id_package === member.idPackage
        )?.name;

        return {
          userId: member.idUser,
          email: member.email,
          endDate: moment(member.packageEnd).format('MM-DD-YYYY'),
          startDate: moment(member.packageStart).format('MM-DD-YYYY'),
          name: `${member.firstName} ${member.lastName}`,
          package: packageName,
          downloadLimit: member.downloads.quota,
          downloadsUsed: member.downloads.usage,
          admin: member.adminPriv,
          owner: member.isOwner,
          expired: packageName === 'Expired Package',
        };
      });
  }, [teamMembers, packagesData, teamMemberFilter]);

  const formerData: FormerTeamMemberData[] = useMemo(() => {
    if (!teamMembers) {
      return [];
    }

    return teamMembers.oldTeamMembers.map((member) => {
      const packageName = packagesData?.packages.find(
        (packageInfo) => packageInfo.id_package === member.idPackage
      )?.name;

      return {
        userId: member.idUser,
        email: member.email,
        endDate: moment(member.packageEnd).format('MM-DD-YYYY'),
        startDate: moment(member.packageStart).format('MM-DD-YYYY'),
        name: `${member.firstName} ${member.lastName}`,
        package: packageName,
      };
    });
  }, [teamMembers, packagesData, teamMemberFilter]);

  return {
    data,
    formerData,
    formerColumns,
    columns,
    refetchGetTeamMembers,
    setTeamMemberFilter,
    isLoadingTeamMembers
  };
};
