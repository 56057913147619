import {
  GetProfileJobFocusRequest,
  GetProfileJobFocusResponse,
} from '@cbinsights/userintelservice/userintelservice';
import { request } from 'client/modules/common/utils/request';

export const getProfileJobFocus = (
  req: Partial<GetProfileJobFocusRequest>
): Promise<GetProfileJobFocusResponse> =>
  request({
    url: '/service/userintel/GetProfileJobFocus',
    body: req,
  }).then((r) => r.body);
