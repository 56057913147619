import {
  GetProfileInterestsRequest,
  GetProfileInterestsResponse,
} from '@cbinsights/userintelservice/userintelservice';
import { request } from 'client/modules/common/utils/request';

export const getProfileInterests = (
  req: Partial<GetProfileInterestsRequest>
): Promise<GetProfileInterestsResponse> =>
  request({
    url: '/service/userintel/GetProfileInterests',
    body: req,
  }).then((r) => r.body);
