import { UseQueryResult, useQuery } from 'react-query';
import { GetProfileJobFocusResponse } from '@cbinsights/userintelservice/userintelservice';
import { GetProfileJobFocus } from '.';
import { getProfileJobFocus } from '../api/GetProfileJobFocus';

type Params = Parameters<typeof getProfileJobFocus>[0];
type Return = GetProfileJobFocusResponse;

export const useGetProfileJobFocus = ({
  enabled = false,
  params,
}: {
  enabled: boolean;
  params: Params;
}): UseQueryResult<Return, Error> =>
  useQuery<Return, Error, Return, [GetProfileJobFocus, Params]>(
    ['GetProfileJobFocus', params],
    () => getProfileJobFocus(params),
    {
      enabled,
      notifyOnChangeProps: ['data', 'isFetching'],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
