import React from 'react';
import {
  Col,
  Empty,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
  message,
} from 'antd';
import EditableText from 'client/component-library/EditableText/EditableText';
import { Link } from 'react-router-dom';
import { User } from '@cbinsights/userv2service/userv2service';
import UTCTooltip from 'client/utils/dates';
import { validateEmailFormat } from 'client/modules/common/utils/validateEmailFormat';
import { useUpdateUser } from '../hooks/useUpdateUser';
import useChangePortal from '../hooks/useChangePortal';

type Props = {
  userId: number;
  email: string;
  company: string;
  isActive: boolean;
  firtsName: string;
  lastName: string;
  teamName: string;
  teamId: number;
  refetchUserQuery: () => void;
  portal: number;
  ts_created: string;
  recurring: boolean;
};

const UserDetail = ({
  userId,
  email,
  company,
  isActive,
  firtsName,
  lastName,
  teamName,
  teamId,
  portal,
  refetchUserQuery,
  ts_created,
  recurring,
}: Props) => {
  const { onSubmit } = useUpdateUser();

  const { options, handleChange } = useChangePortal({ userId });

  const updateUser = (data: Partial<User>) => {
    onSubmit(userId, data)
      .then(() => {
        message.success('User successfully updated');
        refetchUserQuery();
      })
      .catch((e) => {
        if (
          e.response?.body?.error?.details?.includes('Email already exists')
        ) {
          message.error(
            'The email you entered is already in use. Please try again.'
          );
        } else {
          message.error('Something went wrong. Please try again later');
        }
        // revert mutation update
        refetchUserQuery();
      });
  };

  const emailValidation = (value: string) => {
    if (!validateEmailFormat(value)) {
      return 'Please enter a valid email';
    }
    return null;
  };

  const nameValidation = (value: string) => {
    if (!value) {
      return 'Please enter a valid name';
    }
    return null;
  };

  return (
    <>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip
              placement="topLeft"
              title="Denotes whether or not their account has been activated. If disabled, user cannot log in"
            >
              Active
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <Switch
            checked={isActive}
            onChange={() => {
              updateUser({ is_active: !isActive });
            }}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            Recurring
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <Switch checked={recurring} disabled />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip
              placement="topLeft"
              title="The email address used for logging in and all customer communications."
            >
              Email
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <EditableText
            text={email}
            validation={emailValidation}
            onSave={(updatedEmail) => {
              updateUser({ email: updatedEmail });
            }}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip
              placement="topLeft"
              title="First name of the user. Displayed in UI and used in communications from the platform."
            >
              First Name
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <EditableText
            text={firtsName}
            validation={nameValidation}
            onSave={(updatedFirstName) => {
              updateUser({ first_name: updatedFirstName });
            }}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip
              placement="topLeft"
              title="Last name of the user. Displayed in UI and used in communications from the platform."
            >
              Last Name
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <EditableText
            text={lastName}
            validation={nameValidation}
            onSave={(updatedLastName) => {
              updateUser({ last_name: updatedLastName });
            }}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip placement="topLeft" title="The date the user was created.">
              Created At
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <UTCTooltip date={ts_created} />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip
              placement="topLeft"
              title="The user's company. Changing this does not affect the team assignment."
            >
              Company
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <EditableText
            text={company}
            onSave={(updatedcompany) => {
              updateUser({ company: updatedcompany });
            }}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip placement="topLeft" title="The user's team.">
              Team
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          {teamId ? (
            <Link className="underline" to={`/teams/${teamId}`}>
              {teamName}
            </Link>
          ) : (
            'Not on a team'
          )}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip
              placement="topLeft"
              title="Only used for university users. Should be set to CB Insights for all normal users."
            >
              Portal
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <Select
            style={{ width: '100%', maxWidth: '200px' }}
            showSearch
            placeholder="Select a Portal"
            options={options}
            optionFilterProp="label"
            notFoundContent={<Empty />}
            value={portal}
            onChange={(newPortal) =>
              handleChange(newPortal, onSubmit, refetchUserQuery)
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default UserDetail;
