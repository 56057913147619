// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.7.0
//   protoc               v6.30.2
// source: cbinsights/espmatrixservice/espmatrixservice.proto

/* eslint-disable */

export const protobufPackage = "espmatrixservice";

/** version 5.11.0 */

export enum MarketEntityInReviewStatus {
  MARKET_ENTITY_IN_REVIEW_CURRENT = "MARKET_ENTITY_IN_REVIEW_CURRENT",
  MARKET_ENTITY_IN_REVIEW_ADDED = "MARKET_ENTITY_IN_REVIEW_ADDED",
  MARKET_ENTITY_IN_REVIEW_DELETED = "MARKET_ENTITY_IN_REVIEW_DELETED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum MarketEntitySource {
  MARKET_ENTITY_SOURCE_UNSPECIFIED = "MARKET_ENTITY_SOURCE_UNSPECIFIED",
  MARKET_ENTITY_SOURCE_ADMIN = "MARKET_ENTITY_SOURCE_ADMIN",
  MARKET_ENTITY_SOURCE_MODEL = "MARKET_ENTITY_SOURCE_MODEL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum EspSortField {
  /** UNSPECIFIED - Defaults to TITLE (cbi_esp_matrix.esp_market.esp_market) */
  UNSPECIFIED = "UNSPECIFIED",
  TITLE = "TITLE",
  ID_ESP_MARKET = "ID_ESP_MARKET",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum VideoSource {
  UnknownSource = "UnknownSource",
  Youtube = "Youtube",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum ChartType {
  UNKNOWN = "UNKNOWN",
  EXITS = "EXITS",
  REVENUE = "REVENUE",
  REVENUE_MULTIPLE = "REVENUE_MULTIPLE",
  FUNDING_PER_EMPLOYEE = "FUNDING_PER_EMPLOYEE",
  REVENUE_PER_EMPLOYEE = "REVENUE_PER_EMPLOYEE",
  VALUATION_PER_EMPLOYEE = "VALUATION_PER_EMPLOYEE",
  VALUATION = "VALUATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** admin */
export interface ListMarketsForAdminRequest {
  markets_in_review_only: boolean;
}

export interface ListMarketsForAdminResponse {
  markets: MarketInReview[];
}

export interface MarketInReview {
  id_market: number;
  id_market_review: number;
  market_name: string;
  market_description: string;
  id_industries: number[];
  market_entities_in_review: MarketEntityInReview[];
  ts_updated: string;
  ts_inserted: string;
  modifiers: string[];
}

export interface MarketEntityInReview {
  id_cbi_entity: number;
  status: MarketEntityInReviewStatus;
  consider_for_scoring: boolean;
}

export interface GetMarketDetailsForAdminRequest {
  id_market: number;
  id_market_review: number;
}

export interface GetMarketDetailsForAdminResponse {
  market_in_review: MarketInReview | undefined;
  market: MarketInReview | undefined;
}

export interface UpsertMarketInReviewForAdminRequest {
  market: MarketInReview | undefined;
}

export interface UpsertMarketInReviewForAdminResponse {
  id_market_review: number;
  created: boolean;
}

export interface UpsertMarketForAdminRequest {
  market: MarketInReview | undefined;
}

export interface UpsertMarketForAdminResponse {
  id_market: number;
  created: boolean;
}

export interface DeleteMarketInReviewForAdminRequest {
  id_market_review: number;
}

export interface DeleteMarketInReviewForAdminResponse {
  success: boolean;
}

export interface RefreshMarketCompetitorsForAdminRequest {
  id_markets: number[];
}

export interface RefreshMarketCompetitorsForAdminResponse {
  success: boolean;
}

export interface MarketEntitySourceMapping {
  market_entity_source: MarketEntitySource;
  id_orgs: number[];
  id_suggestion_run: number;
}

export interface UpsertMarketRequest {
  id_market_map: number;
  market_name: string;
  market_description: string;
  marketmap_research_url: string;
  id_industries: number[];
  /** TODO: remove and use org_market_scores for list of entities */
  id_cbi_entities: number[];
  ts_published: Date | undefined;
  id_market: number;
  b2b: boolean;
  b2c: boolean;
  id_esp_market: number;
  org_market_scores: OrgMarketScore[];
  is_score_update: boolean;
  /** New entity source mapping */
  market_entity_source_mappings_to_add: MarketEntitySourceMapping | undefined;
  request_source: Event_RequestSource;
}

export interface UpsertMarketResponse {
  id_market: number;
  created: boolean;
}

export interface DeleteMarketRequest {
  id_market: number;
}

/** create */
export interface BatchCreateMatricesRequest {
  matrices: MatrixInfo[];
}

export interface BatchCreateMatricesResponse {
  matrices: MatrixInfo[];
}

/** read */
export interface ListEspMarketsRequest {
  pagination: Pagination | undefined;
  id_industries: number[];
  id_esp_markets: number[];
  id_markets: number[];
  /** if you don't need industry info, set exclude_industries=True to make for faster calls */
  exclude_industries: boolean;
  sort_field: EspSortField;
  /** Ascending if false */
  sort_desc: boolean;
  /** Deprecated; use id_industries (`parent` is a single id_industry) */
  parent: number;
}

export interface ListEspMarketsResponse {
  esp_markets: EspMarket[];
}

export interface Market {
  id_market: number;
  id_market_map: number;
  market_name: string;
  market_description: string;
  marketmap_research_url: string;
  industries: Industry[];
  similar_markets: Market[];
  esp_metadata: EspMetaData | undefined;
  ts_published: Date | undefined;
  b2b: boolean;
  b2c: boolean;
  id_market_redirect: number;
  market_display: string;
  /** Flag for reviewed market */
  is_vetted: boolean;
  ts_updated: Date | undefined;
  ts_inserted: Date | undefined;
}

export interface AbData {
  employee_full_time_12_months_ago: string;
  employee_full_time_today: string;
  avg_implementation_cost: string;
  avg_sales_cycle: string;
  avg_annual_contract_value: string;
  customer_evaluation_kpis: string[];
  avg_deployment_time: string;
  cert_compliance: string;
  partners: AbPartner[];
  customer_count_current: string;
  customer_count_past: string;
  customer_distribution: AbCustomerDistribution | undefined;
  customers: AbCustomer[];
  pricing_model: string;
  customer_typical_size: string;
}

export interface AbPartner {
  partner_integration_name: string;
  partner_integration_url: string;
  partner_integration_id_org: number;
  partner_integration_logo_url: string;
}

export interface AbCustomerDistribution {
  middle_eastern_customer_percentage: number;
  north_american_customer_percentage: number;
  western_europe_customer_percentage: number;
  eastern_europe_customer_percentage: number;
  south_american_customer_percentage: number;
  african_customer_percentage: number;
  asian_customer_percentage: number;
  geo_coverage: string;
}

export interface AbCustomer {
  name: string;
  url: string;
  id_org: number;
}

export interface EspMetaData {
  id_esp_market: number;
  id_current_org_matrix: number;
}

/** read */
export interface ListMarketsRequest {
  pagination: Pagination | undefined;
  id_markets: number[];
  id_industries: number[];
  id_cbi_entities: number[];
  id_esp_markets: number[];
  skip_similar: boolean;
  include_deleted_markets: boolean;
}

export interface ListMarketsResponse {
  markets: Market[];
}

export interface Investor {
  id_org: number;
  org_name: string;
  org_id_investor_category: number;
}

export interface KeyInvestor {
  org_investor: Investor[];
  id_org: number;
}

export interface GetExtendedMarketDetailsRequest {
  id_market: number;
  id_esp_market: number;
  includeInvestors: boolean;
  includeHeadcount: boolean;
  includeTranscripts: boolean;
}

export interface GetHeadcountResponse {
  headcounts: Headcount[];
  id_cbi_entity: number;
  six_month_growth: Percent | undefined;
  one_year_growth: Percent | undefined;
  two_year_growth: Percent | undefined;
  most_recent_headcount: Headcount | undefined;
  has_headcount_data: boolean;
  headcount_min_date: string;
  headcount_max_date: string;
}

export interface Headcount {
  as_of_date: string;
  headcount: number;
  percent_change: Percent | undefined;
  is_current_headcount: boolean;
  base_date_for_percent_change: string;
}

export interface Percent {
  percent: number;
  isValid: boolean;
}

export interface TranscriptMetaData {
  has_transcript: boolean;
  id_cbi_entity: number;
  id_transcript: string;
}

export interface GetExtendedMarketDetailsResponse {
  id_market: number;
  headcount: GetHeadcountResponse[];
  key_investors: KeyInvestor[];
  transcripts_metadata: TranscriptMetaData[];
}

export interface GetMarketDetailsRequest {
  id_market: number;
  id_esp_market: number;
  skipOrgDetails: boolean;
  includeDemoVideos: boolean;
  /** usage requires passing in features in user metadata */
  includeAbData: boolean;
}

export interface GetMarketDetailsResponse {
  market: Market | undefined;
  entities: OrgInfo[];
}

export interface BatchGetMarketDetailsRequest {
  id_markets: number[];
  id_industries: number[];
  id_esp_markets: number[];
  market_limit: number;
  skipOrgDetails: boolean;
  skip_file_attachments: boolean;
  skip_mosaic_sort: boolean;
  skip_similar: boolean;
}

export interface BatchGetMarketDetailsResponse {
  market_details: GetMarketDetailsResponse[];
}

export interface GetIdMarketForEspRequest {
  id_esp_market: number;
}

export interface GetIdMarketForEspResponse {
  id_market: number;
}

export interface ListIndustriesRequest {
}

export interface ListIndustriesResponse {
  industries: Industry[];
}

export interface ListFunctionsRequest {
}

export interface ListFunctionsResponse {
  functions: FunctionMessage[];
}

/** update */
export interface BatchUpdateEspMarketsRequest {
  requests: UpdateEspMarketRequest[];
}

export interface UpdateEspMarketRequest {
  esp_market: EspMarket | undefined;
  update_mask: string[] | undefined;
}

export interface BatchUpdateEspMarketsResponse {
}

/** delete */
export interface BatchDeleteEspMarketsRequest {
  id_esp_markets: number[];
}

export interface GetOrgMatrixRequest {
  /** ID of the matrix to retrieve. */
  id: number;
  skipOrgDetails: boolean;
}

export interface ListOrgPositionsRequest {
  /** Placeholder - not implemented */
  parent: number;
  filter: string;
  pagination: Pagination | undefined;
}

export interface ListOrgPositionsResponse {
  org_positions: OrgPosition[];
}

/** common */
export interface Pagination {
  limit: number;
  offset: number;
}

export interface MatrixInfo {
  esp_market: EspMarket | undefined;
  org_matrix: OrgMatrix | undefined;
}

export interface EspMarket {
  id_esp_market: number;
  esp_market: string;
  /** not used */
  industries: Industry[];
  functions: FunctionMessage[];
  ts_created: Date | undefined;
  ts_last_update: Date | undefined;
  description: string;
  id_current_org_matrix: number;
  id_market: number;
  ts_published: Date | undefined;
  id_industries: number[];
}

export interface OrgMatrix {
  id_org_matrix: number;
  id_parent: number;
  revision: string;
  org_positions: OrgPosition[];
}

/**
 * OrgPosition contains the position of an organization in a matrix
 * as well details about the organization itself.
 */
export interface OrgPosition {
  /** The resource name of the OrgPosition, for example: "espMarket/1/orgMatrix/2/orgPosition/1". */
  name: string;
  /** The position of the organization in the matrix. */
  coordinates:
    | Coordinates
    | undefined;
  /** The organization details. */
  org: OrgInfo | undefined;
}

export interface Mosaic {
  management: number;
  market: number;
  momentum: number;
  money: number;
  overall: number;
}

export interface OrgInfo {
  id_org: number;
  name: string;
  description: string;
  founded_year: number;
  last_funding_round_date: string;
  last_funding_round_amount: number;
  clients: BusinessRelationshipInfo | undefined;
  partners: BusinessRelationshipInfo | undefined;
  people_info: PeopleInfo | undefined;
  country: string;
  logo_url: string;
  default_entity_type: string;
  has_analyst_briefing: boolean;
  id_company: number;
  mosaic: Mosaic | undefined;
  attachments: FileAttachment[];
  slug: string;
  company_demo_videos: CompanyDemoVideo[];
  total_funding: number;
  last_exit_funding_round: string;
  last_funding_round: string;
  revenue: Revenue | undefined;
  ab_data: AbData | undefined;
  valuation: number;
  org_market_score: OrgMarketScore | undefined;
  id_status: number;
  url: string;
  state: string;
  city: string;
  org_total_equity_funding: number;
  market_capitalization: number;
  last_exit_exit_indicator: boolean;
  has_headcount: boolean;
  id_sources: number[];
}

export interface OrgMarketScore {
  id_org: number;
  market_strength_score: number;
  execution_strength_score: number;
  overall_score: number;
  consider_for_scoring: boolean;
}

export interface Revenue {
  valuation: number;
  max_or_min_amount: number;
  max_or_min_multiple: number;
}

export interface CompanyDemoVideo {
  idCompanyVideo: number;
  rankingScore: number;
  source: VideoSource;
  idVideo: string;
}

export interface FileAttachment {
  file_s3_uri: string;
  file_name: string;
  content_type: string;
  s3_key: string;
  thumbnail_s3_key: string;
  thumbnail_s3_uri: string;
  file_type: string;
}

export interface BusinessRelationshipInfo {
  /** total count of orgs that have this relationship */
  total_count: number;
  /** 5 most recent orgs in this relationship */
  recent_relationship_orgs: BusinessRelationshipOrg[];
}

export interface PeopleInfo {
  /** count of all people */
  total_count: number;
  /** just a select few key people */
  key_people: Person[];
}

export interface Person {
  id_person: number;
  full_name: string;
  title: string;
}

export interface BusinessRelationshipOrg {
  id_org: number;
  org_name: string;
  logo_url: string;
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface Industry {
  name: string;
  id: number;
  sub_industries: Industry[];
  id_parent: number;
  id_research_taxonomy_term: number;
}

export interface FunctionMessage {
  name: string;
  id: number;
  sub_functions: FunctionMessage[];
  id_parent: number;
}

/**
 * Represents a CRUD resource event. This message type is published to the SNS event queue
 * for processing by the cbi-search-indexing-job.
 */
export interface Event {
  /** The type of event that occurred for the resource. */
  event_type: Event_EventType;
  /** The type of resource that was modified. */
  resource_type: Event_ResourceType;
  /** The ID of the resource that was modified. */
  resource_id: number;
  request_source: Event_RequestSource;
}

/** Possible event types. Generally follows CRUD semantics. */
export enum Event_EventType {
  /** EVENT_TYPE_UNSPECIFIED - No event type was set. This is not a valid event type. */
  EVENT_TYPE_UNSPECIFIED = "EVENT_TYPE_UNSPECIFIED",
  /** EVENT_TYPE_CREATE - A resource was created. */
  EVENT_TYPE_CREATE = "EVENT_TYPE_CREATE",
  /** EVENT_TYPE_DELETE - A resource was deleted */
  EVENT_TYPE_DELETE = "EVENT_TYPE_DELETE",
  /** EVENT_TYPE_UPDATE - A resource was updated -- NOT IMPLEMENTED */
  EVENT_TYPE_UPDATE = "EVENT_TYPE_UPDATE",
  /** EVENT_TYPE_REFRESH_COMPETITORS - A resource's competitors should be refreshed */
  EVENT_TYPE_REFRESH_COMPETITORS = "EVENT_TYPE_REFRESH_COMPETITORS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The type of resource */
export enum Event_ResourceType {
  /** RESOURCE_TYPE_UNSPECIFIED - No resource type was set. This is not a valid resource type. */
  RESOURCE_TYPE_UNSPECIFIED = "RESOURCE_TYPE_UNSPECIFIED",
  /** RESOURCE_TYPE_ESP_MARKET - The resource is an ESP Market */
  RESOURCE_TYPE_ESP_MARKET = "RESOURCE_TYPE_ESP_MARKET",
  /** RESOURCE_TYPE_MARKET - The resource is a Market */
  RESOURCE_TYPE_MARKET = "RESOURCE_TYPE_MARKET",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The service/job that triggered the event. */
export enum Event_RequestSource {
  REQUEST_SOURCE_UNSPECIFIED = "REQUEST_SOURCE_UNSPECIFIED",
  REQUEST_SOURCE_ADMIN = "REQUEST_SOURCE_ADMIN",
  REQUEST_SOURCE_MARKET_SCORING_JOB = "REQUEST_SOURCE_MARKET_SCORING_JOB",
  REQUEST_SOURCE_MARKET_SUGGESTION_JOB = "REQUEST_SOURCE_MARKET_SUGGESTION_JOB",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface GetMarketGAIDataRequest {
  id_market: number;
}

export interface GetMarketGAIDataResponse {
  id_market: number;
  market_entities: MarketEntity[];
  market_overview: SectionContent[];
}

export interface MarketEntity {
  id_cbi_entity: number;
  sections: SectionContent[];
}

export interface SectionContent {
  id_section: number;
  section_name: string;
  paragraphs: ParagraphContent[];
}

export interface ParagraphContent {
  paragraph_index: number;
  content: string;
}

export interface ListCustomerQuotesRequest {
  id_cbi_entities: number[];
}

export interface QuoteData {
  id_quote: number;
  quote: string;
  citation: string;
}

export interface CustomerQuotes {
  id_cbi_entity: number;
  quote_data: QuoteData[];
}

export interface ListCustomerQuotesResponse {
  quotes: CustomerQuotes[];
}

export interface GetSimilarMarketsRequest {
  id_market: number;
}

export interface GetSimilarMarketsResponse {
  /**
   * Each market contains only the following fields, if available:
   * id_market, market_name, market_description
   */
  similar_markets: Market[];
}

export interface GetMarketDataChartsRequest {
  id_orgs: number[];
  /** If chart_types is empty, will return all chart data */
  chart_types: ChartType[];
}

export interface GetMarketDataChartsResponse {
  exits: MarketDataChartExit[];
  orgs: OrgInfo[];
  revenue: MarketDataChartRevenue[];
  revenue_multiple: MarketDataChartRevenueMultiple[];
  funding_per_employee: MarketDataChartFundingPerEmployee[];
  revenue_per_employee: MarketDataChartRevenuePerEmployee[];
  valuation_per_employee: MarketDataChartValuationPerEmployee[];
  valuation: MarketDataChartValuation[];
}

export interface MarketDataChartExit {
  entity: OrgInfo | undefined;
  last_exit: Exit | undefined;
  last_funding: Funding | undefined;
}

export interface Exit {
  /** Fields coming from CSS */
  exit_indicator: boolean;
  funding_date: string;
  funding_round: string;
  funding_amount: number;
  funding_max_valuation: number;
  investors: CoInvestor[];
}

export interface Funding {
  date: string;
  round: string;
  amount: number;
  max_valuation: number;
  investors: CoInvestor[];
}

export interface CoInvestor {
  name: string;
  id_org: number;
  id: number;
  logo_url: string;
}

export interface MarketDataChartRevenue {
  id_org: number;
  revenue_valuation: number;
  latest_revenue_amount: number;
  latest_revenue_multiple: number;
  latest_revenue_date: string;
}

export interface MarketDataChartRevenueMultiple {
  id_org: number;
  latest_revenue_amount: number;
  latest_revenue_multiple: number;
  latest_revenue_date: string;
  latest_valuation_year: number;
  latest_valuation: number;
}

export interface MarketDataChartFundingPerEmployee {
  id_org: number;
  latest_funding: number;
  funding_per_employee: number;
  headcount: number;
  latest_funding_date: string;
  headcount_most_recent_date: string;
}

export interface MarketDataChartValuationPerEmployee {
  id_org: number;
  latest_valuation: number;
  valuation_per_employee: number;
  headcount: number;
  market_cap: number;
  headcount_date: string;
  latest_valuation_date: string;
}

export interface MarketDataChartRevenuePerEmployee {
  id_org: number;
  latest_revenue: number;
  revenue_per_employee: number;
  headcount: number;
  headcount_date: string;
  latest_revenue_date: string;
}

export interface MarketDataChartValuation {
  id_org: number;
  max_valuation: number;
  min_valuation: number;
}
