import { Form, Input, Modal, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
  GetProfileJobFocusResponse,
  IntelSource,
} from '@cbinsights/userintelservice/userintelservice';
import { updateJobFocus } from '../services/api/UpdateProfileJobFocus';

const { TextArea } = Input;

type Props = {
  userId: number;
  jobFunction?: number;
  jobName?: string;
  focusAreas?: string;
  jobOptions: Array<{ label: string; value: number; description: string }>;
  profileJobFocus: GetProfileJobFocusResponse;
  refetchJobFocus: () => void;
  open: boolean;
  isOpen: (open: boolean) => void;
};

type EditJobFunctionForm = {
  jobFunction: number;
  focusAreas: string;
  otherJobFunction: string;
};

const OTHER_JOB_FUNCTION_ID = 6;

const EditJobFunction = ({
  userId,
  jobFunction,
  focusAreas,
  jobOptions,
  profileJobFocus,
  refetchJobFocus,
  open,
  isOpen,
  jobName,
}: Props) => {
  const [form] = Form.useForm<EditJobFunctionForm>();
  const [selectedJobFunction, setSelectedJobFunction] = useState<
    number | undefined
  >(jobFunction);

  const onSelectJobFocus = (value) => {
    setSelectedJobFunction(value);

    if (value !== OTHER_JOB_FUNCTION_ID) {
      const selected = profileJobFocus?.allJobFunctions.find(
        (job) => job.idJobFunction === value
      );
      form.setFieldsValue({
        focusAreas: selected?.description,
        otherJobFunction: undefined,
      });
    } else {
      form.setFieldsValue({ otherJobFunction: '' });
    }

    const selected = profileJobFocus?.allJobFunctions.find(
      (job) => job.idJobFunction === value
    );
    form.setFieldValue('focusAreas', selected?.description);
  };

  const updateJobFunctionMutation = useMutation(updateJobFocus);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const jobFunctionName =
          values.jobFunction === OTHER_JOB_FUNCTION_ID
            ? values.otherJobFunction
            : '';

        const paths = [
          'jobFunction.jobFunction.idJobFunction',
          'jobFunction.source',
          'jobFunction.userValidated',
          'focusAreas.focusAreas',
          'focusAreas.source',
          'focusAreas.userValidated',
        ];

        if (values.jobFunction === OTHER_JOB_FUNCTION_ID) {
          paths.push('jobFunction.jobFunction.name');
        }

        updateJobFunctionMutation
          .mutateAsync({
            idUser: userId,
            jobFocus: {
              focusAreas: {
                focusAreas: values.focusAreas,
                optIn: true,
                userValidated: false,
                source: IntelSource.SOURCE_UNSPECIFIED,
              },
              jobFunction: {
                jobFunction: {
                  idJobFunction: values.jobFunction,
                  name: jobFunctionName,
                  description: jobOptions.find(
                    (entry) => entry.value === values.jobFunction
                  )?.description,
                },
                optIn: true,
                source: IntelSource.SOURCE_UNSPECIFIED,
                userValidated: false,
              },
              responsibility: undefined,
              title: undefined,
            },
            update_mask: {
              paths,
            },
          })
          .then(() => {
            refetchJobFocus();
            form.resetFields();
            isOpen(false);
            message.success('Job function updated successfully');
          })
          .catch(() => {
            message.error(
              'Failed to update job function. Please try again later'
            );
          });
      })
      .catch(() => {});
  };

  const resetForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    setSelectedJobFunction(jobFunction);
    form.setFieldValue('jobFunction', jobFunction);
  }, [jobFunction]);

  useEffect(() => {
    form.setFieldValue('focusAreas', focusAreas);
  }, [focusAreas]);

  useEffect(() => {
    form.setFieldValue('otherJobFunction', jobName);
  }, [jobName]);

  return (
    <div>
      <Modal
        title="Edit Job Function"
        centered
        open={open}
        onOk={handleSubmit}
        onCancel={() => {
          resetForm();
          isOpen(false);
        }}
        okText="Save"
        closable={false}
        okButtonProps={{ loading: updateJobFunctionMutation.isLoading }}
        cancelButtonProps={{ disabled: updateJobFunctionMutation.isLoading }}
        width="50%"
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={() => {
            isOpen(false);
          }}
          initialValues={{ jobFunction, focusAreas, otherJobFunction: jobName }}
        >
          <Form.Item
            name="jobFunction"
            label="Job function"
            rules={[{ required: true }]}
            help={false}
            initialValue={jobFunction}
          >
            <Select options={jobOptions} onSelect={onSelectJobFocus} />
          </Form.Item>

          {selectedJobFunction === OTHER_JOB_FUNCTION_ID && (
            <Form.Item
              name="otherJobFunction"
              label=""
              rules={[
                { required: true, message: 'Please enter the job function' },
              ]}
              initialValue={jobName}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            name="focusAreas"
            label="Focus Areas"
            rules={[{ required: true }]}
            help={false}
            initialValue={focusAreas}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditJobFunction;
