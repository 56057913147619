import {
  LiveSearchRequest,
  LiveSearchResponse,
} from '@cbinsights/integratedsearchservice/integratedsearchservice';
import { request } from 'client/modules/common/utils/request';

export const getLiveSearch = (
  req: Partial<LiveSearchRequest>
): Promise<LiveSearchResponse> =>
  request({
    url: '/service/integratedsearchservice/LiveSearch',
    body: req,
  }).then((r) => r.body);
