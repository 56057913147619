import React, { useMemo } from 'react';
import { IdType } from '@cbinsights/cbientityservice/cbientityservice';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { useGetGeneralCbiEntityInfo } from 'client/modules/cbi-entity/services/general-info/hooks/useGetGeneralCbiEntityInfo';
import EntityLinking from 'client/modules/team/components/EntityLinking';
import { EditOutlined } from '@ant-design/icons';
import { IntelSource } from '@cbinsights/userintelservice/userintelservice';
import EditJobFunction from './EditJobFunction';
import { useGetProfileJobFocus } from '../services/hooks/useGetProfileJobFocus';
import InterestSettings from './InterestSettings';
import { useGetProfileInterests } from '../services/hooks/useGetProfileInterests';

type Props = {
  linkedEntity: number;
  userId: number;
  refetchUserQuery: () => void;
};

const PersonalizationSettings = ({
  linkedEntity,
  userId,
  refetchUserQuery,
}: Props) => {
  const { data: entityInfo, isLoading: isLoadingEntity } =
    useGetGeneralCbiEntityInfo(Boolean(linkedEntity), {
      id: linkedEntity,
      idType: IdType.CBI_ENTITY,
    });

  const [openInterestModal, setOpenInterestModal] = React.useState(false);
  const [openJobFunctionModal, setIsOpenJobFuctionModal] =
    React.useState(false);

  const { data: profileJobFocus, refetch: refetchJobFocus } =
    useGetProfileJobFocus({ enabled: true, params: { idUser: userId } });
  const { data: profileInterests, refetch: refetchProfileInterests } =
    useGetProfileInterests({ enabled: true, params: { idUser: userId } });

  const defaultInterestSettings = useMemo(() => {
    if (!profileInterests) {
      return { topics: [], targetRegion: [] };
    }

    return {
      topics: profileInterests?.interests?.topics?.map((topic) => topic) || [],
      targetRegion:
        profileInterests?.interests?.targetRegions?.map((region) => region) ||
        [],
    };
  }, [profileInterests]);

  const jobOptions = useMemo(() => {
    if (!profileJobFocus) {
      return [];
    }
    return profileJobFocus.allJobFunctions.map((entry) => ({
      label: entry.name,
      value: entry.idJobFunction,
      description: entry.description,
    }));
  }, [profileJobFocus]);

  const selectedJobFunction = profileJobFocus?.jobFocus?.jobFunction;
  const selectedFocusAreas = profileJobFocus?.jobFocus?.focusAreas;

  const disabledJobFunction =
    selectedJobFunction?.source === IntelSource.SOURCE_USER_INPUT ||
    selectedJobFunction?.userValidated === true;

  return (
    <>
      <Row>
        <Col span="10">
          <Typography.Title level={5} className="!font-medium">
            Linked Entity
          </Typography.Title>
        </Col>
        <Col span="14" className="text-right">
          <EntityLinking
            entity={entityInfo}
            isLoading={isLoadingEntity}
            onEntityChange={refetchUserQuery}
            idUser={userId}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span="10">
          <Typography.Title level={5} className="!font-medium">
            Job function
          </Typography.Title>
        </Col>
        <Col span="14" className="text-right">
          <span className="mr-2">{selectedJobFunction?.jobFunction.name}</span>
          <Tooltip
            title={
              disabledJobFunction
                ? 'User validated job function cannot be edited'
                : ''
            }
          >
            <Button
              type="default"
              shape="circle"
              icon={<EditOutlined style={{ fontSize: '14px' }} />}
              onClick={() => setIsOpenJobFuctionModal(true)}
              disabled={disabledJobFunction}
            />
          </Tooltip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span="10">
          <Typography.Title level={5} className="!font-medium">
            Focus areas
          </Typography.Title>
        </Col>
        <Col span="14" className="text-right">
          <span>{selectedFocusAreas?.focusAreas}</span>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span="24" className="flex justify-center">
          <EditJobFunction
            open={openJobFunctionModal}
            isOpen={setIsOpenJobFuctionModal}
            userId={userId}
            jobOptions={jobOptions}
            profileJobFocus={profileJobFocus}
            jobFunction={selectedJobFunction?.jobFunction?.idJobFunction}
            focusAreas={selectedFocusAreas?.focusAreas}
            refetchJobFocus={refetchJobFocus}
            jobName={selectedJobFunction?.jobFunction?.name}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span="10">
          <Typography.Title level={5} className="!font-medium">
            Topics
          </Typography.Title>
        </Col>
        <Col span="14" className="text-right flex justify-end items-center">
          <span className="mr-2">
            {defaultInterestSettings.topics
              .map((topic) => topic.topic)
              .join(', ')}
          </span>
          <Button
            type="default"
            shape="circle"
            icon={<EditOutlined style={{ fontSize: '14px' }} />}
            onClick={() => setOpenInterestModal(true)}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span="10">
          <Typography.Title level={5} className="!font-medium">
            Target region
          </Typography.Title>
        </Col>
        <Col span="14" className="text-right flex justify-end items-center">
          <span className="mr-2">
            {defaultInterestSettings.targetRegion
              .map((region) => region.displayText)
              .join(', ')}
          </span>
          <Button
            type="default"
            shape="circle"
            icon={<EditOutlined style={{ fontSize: '14px' }} />}
            onClick={() => setOpenInterestModal(true)}
          />
        </Col>
        <InterestSettings
          open={openInterestModal}
          setIsOpen={setOpenInterestModal}
          defaultTopics={defaultInterestSettings.topics}
          defaultRegions={defaultInterestSettings.targetRegion}
          refetchProfileInterests={refetchProfileInterests}
          idUser={userId}
        />
      </Row>
    </>
  );
};

export default PersonalizationSettings;
