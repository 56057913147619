import React from 'react';
import { Alert, Col, Row, Typography } from 'antd';
import Divider from './Divider';
import EditPackageSettings from './EditPackageSettings';
import EditTrialSettings from './EditTrialSettings';
import { TrialInfo } from '../types/trial';

type Props = {
  packageId: number;
  packageName: string;
  packageStartDate: string;
  packageEndDate: string;
  refetchUserQuery: () => void;
  trialInfo: TrialInfo;
  userId: number;
};

const PackageSettings = ({
  packageId,
  packageEndDate,
  packageName,
  packageStartDate,
  trialInfo,
  refetchUserQuery,
  userId,
}: Props) => {
  return (
    <>
      <Row justify="space-between" className="mb-3">
        {trialInfo.isActive && (
          <Col span="24" className="mb-4">
            <Alert
              message="The user is currently on a trial package. The following information pertains to their original package, which they will return to once the trial period ends"
              type="info"
              showIcon
            />
          </Col>
        )}
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            Package
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <span>
            {trialInfo.isActive ? trialInfo.originalPackageName : packageName}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" className="mb-3">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            Start Date
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <span>{packageStartDate}</span>
        </Col>
      </Row>
      <Row justify="space-between" className="mb-3">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            End Date
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right md:pr-7">
          <span>{packageEndDate}</span>
        </Col>
        <Col span="24">
          <div className="flex justify-center">
            <EditPackageSettings
              packageName={packageName}
              packageID={packageId}
              packageEnd={packageEndDate}
              packageStart={packageStartDate}
              refetchUser={refetchUserQuery}
              trialInfo={trialInfo}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="24 md:pr-7">
          <Divider
            orientationMargin={0}
            className="border-t-2 border-black border-solid"
          />
        </Col>
      </Row>
      <Row justify="space-between" className="mb-1">
        <Col span="12">
          <Typography.Title level={3} className="!font-medium">
            Trial Information
          </Typography.Title>
        </Col>
      </Row>
      {!trialInfo.isActive && (
        <Row className="mb-3">
          <Col span="24 md:pr-7">
            <Alert message="no trial active" type="info" showIcon />
          </Col>
        </Row>
      )}
      {trialInfo.isActive && (
        <>
          <Row justify="space-between" className="mb-3">
            <Col span="12">
              <Typography.Title level={5} className="!font-medium">
                Package
              </Typography.Title>
            </Col>
            <Col span="12" className="text-right md:pr-7">
              <Typography.Text className="!font-medium">
                {trialInfo.packageName ?? 'N/A'}
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="space-between" className="mb-3">
            <Col span="12">
              <Typography.Title level={5} className="!font-medium">
                Start Date
              </Typography.Title>
            </Col>
            <Col span="12" className="text-right md:pr-7">
              <Typography.Text className="!font-medium">
                {trialInfo.startDate ?? 'N/A'}
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="space-between" className="mb-3">
            <Col span="12">
              <Typography.Title level={5} className="!font-medium">
                End Date
              </Typography.Title>
            </Col>
            <Col span="12" className="text-right md:pr-7">
              <Typography.Text className="!font-medium">
                {trialInfo.endDate ?? 'N/A'}
              </Typography.Text>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-3 flex justify-center">
        <EditTrialSettings userId={userId} />
      </Row>
    </>
  );
};

export default PackageSettings;
