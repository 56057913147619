import { Button, Form, Input, Modal, Space, Tag, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
  IntelSource,
  ProfileInterestTopic,
  ProfileTargetRegion,
} from '@cbinsights/userintelservice/userintelservice';
import { updateProfileInterests } from '../services/api/UpdateProfileInterests';

type Props = {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  defaultTopics: ProfileInterestTopic[];
  defaultRegions: ProfileTargetRegion[];
  idUser: number;
  refetchProfileInterests: () => void;
};

type InterestSettingsForm = {
  topics: ProfileInterestTopic[];
  targetRegion: ProfileTargetRegion[];
};

const InterestSettings = ({
  open,
  setIsOpen,
  defaultTopics,
  defaultRegions,
  idUser,
  refetchProfileInterests,
}: Props) => {
  const [form] = Form.useForm<InterestSettingsForm>();
  const [topics, setTopics] = useState<ProfileInterestTopic[]>(defaultTopics);
  const [newTopic, setNewTopic] = useState('');

  const [targetRegions, setTargetRegions] =
    useState<ProfileTargetRegion[]>(defaultRegions);
  const [newTargetRegion, setNewTargetRegion] = useState('');

  const updateProfileInterestsMutation = useMutation(updateProfileInterests);

  useEffect(() => {
    form.setFieldsValue({
      topics: defaultTopics,
      targetRegion: defaultRegions,
    });
    setTopics(defaultTopics);
    setTargetRegions(defaultRegions);
  }, [defaultRegions, defaultTopics]);

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        topics: defaultTopics,
        targetRegion: defaultRegions,
      });
      setTopics(defaultTopics);
      setTargetRegions(defaultRegions);
    }
  }, [open]);

  const resetForm = () => {
    form.resetFields();
    setTopics(defaultTopics);
    setTargetRegions(defaultRegions);
    setNewTopic('');
    setNewTargetRegion('');
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        updateProfileInterestsMutation
          .mutateAsync({
            idUser,
            interests: {
              topics: values.topics?.map((topic) => ({
                topic: topic.topic,
                optIn: topic.optIn,
                userValidated: topic.userValidated || false,
                source: topic.source,
              })),
              targetRegions: values.targetRegion?.map((region) => ({
                displayText: region.displayText,
                optIn: region.optIn,
                userValidated: region.userValidated || false,
                source: region.source,
              })),
            },
            update_mask: { paths: ['topics', 'targetRegions'] },
          })
          .then(() => {
            message.success('Profile Interests updated successfully');
            resetForm();
            refetchProfileInterests();
            setIsOpen(false);
          })
          .catch(() => {
            message.error(
              'Failed to update Profile Interests. Please try again.'
            );
          });
      })
      .catch(() => {});
  };

  const handleAddTopic = () => {
    if (newTopic && !topics.find((topic) => topic.topic === newTopic)) {
      const newTopics = [
        ...topics,
        {
          topic: newTopic,
          source: IntelSource.SOURCE_INTERNAL_INPUT,
          userValidated: false,
          optIn: true,
        },
      ];
      setTopics(newTopics);
      form.setFieldsValue({ topics: newTopics });
      setNewTopic('');
    }
  };

  const handleRemoveTopic = (topicToRemove: string) => {
    const updatedTags = topics.filter((topic) => topic.topic !== topicToRemove);
    setTopics(updatedTags);
    form.setFieldsValue({ topics: updatedTags });
  };

  const handleAddTargetRegion = () => {
    if (
      newTargetRegion &&
      !targetRegions.find((region) => region.displayText === newTargetRegion)
    ) {
      const newRegions: ProfileTargetRegion[] = [
        ...targetRegions,
        {
          displayText: newTargetRegion,
          userValidated: false,
          optIn: true,
          source: IntelSource.SOURCE_INTERNAL_INPUT,
        },
      ];
      setTargetRegions(newRegions);
      form.setFieldsValue({
        targetRegion: newRegions,
      });
      setNewTargetRegion('');
    }
  };

  const handleRemoveTargetRegion = (regionToRemove: string) => {
    const updatedTags = targetRegions.filter(
      (region) => region.displayText !== regionToRemove
    );
    setTargetRegions(updatedTags);
    form.setFieldsValue({ targetRegion: updatedTags });
  };

  return (
    <Modal
      title="Edit Profile Interests"
      centered
      open={open}
      onOk={handleSubmit}
      onCancel={() => {
        form.resetFields();
        setIsOpen(false);
      }}
      okText="Save"
      closable={false}
      okButtonProps={{ loading: false }}
      cancelButtonProps={{ disabled: false }}
      width="50%"
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={() => {
          setIsOpen(false);
        }}
      >
        <Form.Item name="topics" label="Topics" help={false} className="mb-1">
          <Space.Compact block>
            <Input
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Example: large language models"
              onPressEnter={handleAddTopic}
            />
            <Button
              type="primary"
              onClick={handleAddTopic}
              disabled={!newTopic}
            >
              Add
            </Button>
          </Space.Compact>
        </Form.Item>

        <div className="mb-6">
          {topics.map((topic) => (
            <Tooltip
              title={
                topic.userValidated ||
                topic.source === IntelSource.SOURCE_USER_INPUT
                  ? 'User validated topics cannot be edited'
                  : ''
              }
            >
              <Tag
                key={topic.topic}
                closable={
                  !topic.userValidated &&
                  topic.source !== IntelSource.SOURCE_USER_INPUT
                }
                onClose={() => {
                  handleRemoveTopic(topic.topic);
                }}
              >
                {topic.topic}
              </Tag>
            </Tooltip>
          ))}
        </div>
        <Form.Item
          name="targetRegion"
          label="Target region"
          help={false}
          className="mb-1"
        >
          <Space.Compact block>
            <Input
              value={newTargetRegion}
              onChange={(e) => setNewTargetRegion(e.target.value)}
              placeholder="Example: United States"
              onPressEnter={handleAddTargetRegion}
            />
            <Button
              type="primary"
              onClick={handleAddTargetRegion}
              disabled={!newTargetRegion}
            >
              Add
            </Button>
          </Space.Compact>
        </Form.Item>
        <div>
          {targetRegions.map((region) => (
            <Tooltip
              title={
                region.userValidated ||
                region.source === IntelSource.SOURCE_USER_INPUT
                  ? 'User validated target regions cannot be edited'
                  : ''
              }
            >
              <Tag
                key={region.displayText}
                closable={
                  !region.userValidated &&
                  region.source !== IntelSource.SOURCE_USER_INPUT
                }
                onClose={() => {
                  handleRemoveTargetRegion(region.displayText);
                }}
              >
                {region.displayText}
              </Tag>
            </Tooltip>
          ))}
        </div>
      </Form>
    </Modal>
  );
};

export default InterestSettings;
