import { UpdateProfileInterestsRequest } from '@cbinsights/userintelservice/userintelservice';
import { request } from 'client/modules/common/utils/request';

type Props = Omit<Partial<UpdateProfileInterestsRequest>, 'update_mask'> & {
  update_mask: { paths: string[] };
};

export const updateProfileInterests = (req: Props): Promise<void> =>
  request({
    url: '/service/userintel/UpdateProfileInterests',
    body: req,
  }).then((r) => r.body);
